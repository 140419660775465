import { create } from "zustand";
import {
  loginCompany,
  loginWithToken,
  checkUser,
  loginState,
  createCompany,
  confirmAccount,
  twoFactorAuth,
  resetPasswordRequest,
  getCompanyInfo,
  getOverviewContent,
  getDashboardContent,
  updateApplicationForm,
  getCoreCV,
  coreCVPhoneConfirmation,
  getNetworkList,
  companyInvite,
  metaCvActions,
  getCommunityCandidate,
  getContent,
  deleteInvite,
  getGroupList,
  reportActions,
  getAllReports,
  groupActions,
  getSettings,
  setSettings,
  resendCompanyInvite,
  getAid,
  getStrapiContent,
  downloadMetaCv,
  uploadInviteList,
  getJobPosition,
  postJobPosition,
  getCnaDashboard,
  getCnaConnections,
} from "../api/endpoints";
import { toast } from "react-toastify";
import { history } from "../utils/history";

// state baslarken persist mekanigi calismali
// const getInitialState = () => {
//   const storedToken = localStorage.getItem("token");
//   if(storedToken){
//     sendAuthWithToken()
//     if(success){
//       setUser(res.data)
//     }
//     if (error) {
//       localStorage.removeItem("token")
//     }
//   }
//   return INITIAL_STATE
// }

const INITIAL_STATE = {
  cnaConnections: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  cnaDashboard: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  jobPositions: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  aid: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  settings: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  savedReports: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  selectedUsers: [],
  appLanguage: "tr",
  content: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  strapiContent: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  groupList: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  communityCandidate: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  os: null,
  networkList: {
    isLoading: null,
    isLoaded: false,
    data: {},
    memberList: [],
    availableSearchParameters: {},
    currentSearchParameters: {},
  },
  confirmationAction: {},
  coreCV: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  applicationForm: {
    userProfile: {},
    jobDescription: {},
    educationalStatus: [],
  },
  dashboard: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  overview: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  loginData: {
    isLoading: null,
  },
  loginAction: {
    type: null,
    params: [],
  },
  confirmationData: {},
  user: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  userInfo: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  authenticated: false,
  posts: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  searchResult: {
    isLoadingMore: null,
    isLoading: null,
    isLoaded: false,
    totalResult: null,
    totalPages: null,
    data: [],
  },
  fortune: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  cvViewers: {
    isLoading: null,
    isLoaded: false,
    data: {},
  },
  cvStats: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  needHelp: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  favorites: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  filters: {
    isLoading: null,
    isLoaded: false,
    data: [],
  },
  modalScrollY: 0,
};

export const useStore = create((set, get) => ({
  ...INITIAL_STATE,
  setModalScrollY: (value) => {
    set({ modalScrollY: value });
  },
  setLoginAction: (data) => {
    set({ loginAction: data.action });
  },
  setLoginSuccess: (data) => {
    set({ loginAction: data.action });
    if (
      data.api_key !== undefined &&
      data.api_key !== null &&
      data.api_key !== "" &&
      data.action.params.location !== "download-lazy-guru" //if user registered first, dont login
    ) {
      localStorage.setItem("token", data.api_key);
      get().setUser(data);
    }
    if (data.action.type === "redirect") {
      if (data.action.params.location === "recruiterform") {
        switch (data.action.params.parameters.type) {
          case "password":
            history.push("/register/password");
            break;
          case "sms":
            history.push("/register/activation");
            break;
          default:
            history.push("/login");
        }
      } else if (data.action.params.location === "download-lazy-guru") {
        history.push("/download-lazy-guru");
      } else {
        history.push("/login");
      }
    }
  },
  loginCheck: (formData) => {
    // console.log("loginCheck", formData);
    checkUser(
      formData,
      (data) => {
        // console.log(data);
        set({ loginAction: data.action });
        set((state) => ({
          loginData: {
            ...state.loginData,
            user: formData.user,
            // "remember-me": formData["remember-me"],
          },
        }));
        switch (data.action.type) {
          case "proceed_login":
            history.push("/login/password");
            break;
          case "email_auth":
            history.push("/register/activation");
            break;
          default:
            history.push("/login");
            break;
        }
      },
      toast.error,
      (bool) =>
        set((state) => ({
          loginData: { ...state.loginData, isLoading: bool },
        })),
    );
  },
  setUser: (data) => {
    set((state) => ({
      user: {
        ...state.user,
        data: {
          userId: data.user_id,
          userName: data.user_name,
          userCompany: data.company_name,
          userTitle: data.user_title,
          userType: data.user_type,
          userPicture: data.user_profile_picture,
          api_key: data.api_key,
          userCoreCvStatus: data.user_corecv_status,
          defaultCampaign: data.default_campaign,
          languageSetting: data.language.iso2,
        },
        isLoaded: true,
      },
      authenticated: true,
    }));
    get().setAppLanguage(data.language.iso2);
  },
  redirection: (data) => {
    if (data.action.type === "redirect") {
      if (data.action.params.location === "recruiterform") {
        switch (data.action.params.parameters.type) {
          case "password":
            history.push("/register/password");
            break;
          case "sms":
            history.push("/register/activation");
            break;
          default:
            history.push("/login");
        }
      }
    }
  },
  logout: () => {
    localStorage.removeItem("token");
    set((state) => ({
      ...INITIAL_STATE,
      content: state.content,
      appLanguage: state.appLanguage,
      strapiContent: state.strapiContent,
    }));
  },
  persistSession: () => {
    loginWithToken(
      (data) => {
        localStorage.setItem("token", data.api_key);
        get().setUser(data);
      },
      (err) => {
        get().logout();
        history.push("/login");
      },
      (bool) => {
        set((state) => ({ user: { ...state.user, isLoading: bool } }));
      },
    );
  },
  initializeSession: (formData) => {
    // const deviceId = localStorage.getItem("deviceId");
    const loginData = get().loginData;
    const payload = {
      ...formData,
      user: loginData.user,
      // device_identification_code: deviceId,
    };
    loginCompany(
      payload,
      (data) => {
        set({ loginAction: data.action });
        if (data.api_key !== undefined && data.api_key !== null && data.api_key !== "") {
          localStorage.setItem("token", data.api_key);
          get().setUser(data);
        }
      },
      (err) => {
        localStorage.removeItem("token");
        history.push("/login");
        toast.error("Invalid credentials");
      },
      (bool) => {
        set((state) => ({ user: { ...state.user, isLoading: bool } }));
      },
    );
  },
  createCompany: (formData) => {
    createCompany(
      formData,
      (data) => {
        console.log("Account created");
      },
      toast.error,
      (bool) => console.log("Form submitting", bool),
    );
  },
  getCompanyInfo: () => {
    getCompanyInfo(
      (data) => {
        set((state) => ({
          userInfo: { ...state.userInfo, data: data, isLoaded: true },
        }));
      },
      console.log("COMPANY INFO SERVICE ERROR"),
      (bool) => {
        set((state) => ({ userInfo: { ...state.userInfo, isLoading: bool } }));
      },
    );
  },
  getDashboard: () => {
    const localTime = new Date().toTimeString().substring(0, 8);
    console.log(localTime);
    const params = { localtime: localTime };
    getDashboardContent(
      params,
      (data) => {
        set((state) => ({
          dashboard: { ...state.dashboard, data: data.data, isLoaded: true },
        }));
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        set((state) => ({
          dashboard: { ...state.dashboard, isLoading: bool },
        }));
      },
    );
  },
  getOverview: () => {
    getOverviewContent(
      (data) => {
        set((state) => ({
          overview: { ...state.overview, data: data.data, isLoaded: true },
        }));
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        set((state) => ({ overview: { ...state.overview, isLoading: bool } }));
      },
    );
  },
  loginState: (stateData) => {
    loginState(
      stateData,
      (data) => {
        get().setLoginSuccess(data);
      },
      (err) => {
        history.push("/login");
      },
      (bool) => {
        set((state) => ({ user: { ...state.user, isLoading: bool } }));
      },
    );
  },

  resetPasswordRequest: () => {
    const user = get().loginData.user;
    const params = { user: user };
    resetPasswordRequest(
      params,
      (data) => {
        set({ loginAction: data.action });
        toast.success("Password reset link is sent");
        history.push("/login");
      },
      (err) => {
        toast.error("Invalid phone number");
        history.push("/login");
      },
      (bool) => {
        set((state) => ({
          loginData: { ...state.loginData, isLoading: bool },
        }));
      },
    );
  },
  // resetPasswordSubmit: (formData) => {
  //   resetPasswordSubmit(
  //     formData,
  //     (data) => {
  //       set({ loginAction: data.action });
  //       set({ resetAuthCode: formData.auth_code });
  //       switch (data.action.type) {
  //         case "redirect":
  //           if (data.action.params.location === "logout") {
  //             history.push("/login");
  //             toast.success("Password successfully changed");
  //           }
  //           break;
  //         case "proceed_reset":
  //           // toast.success("Valid code");
  //           break;
  //         default:
  //           history.push("/login");
  //           break;
  //       }
  //     },
  //     (err) => {
  //       toast.error("Invalid code");
  //       history.push("/login");
  //     },
  //     (bool) => {
  //       console.log(bool);
  //     }
  //   );
  // },
  confirmUser: (formData) => {
    const payload = {
      ...formData,
      authcode: get().loginAction.params.parameters.authcode,
    };
    confirmAccount(
      payload,
      (data) => {
        set({ loginAction: data.action });
        if (data.api_key !== undefined && data.api_key !== null && data.api_key !== "") {
          localStorage.setItem("token", data.api_key);
          get().setUser(data);
        }
        if (data.action.type === "redirect") {
          if (data.action.params.location === "recruiterform")
            switch (data.action.params.parameters.type) {
              case "password":
                history.push("/register/password");
                break;
              case "sms":
                history.push("/register/activation");
                break;
              default:
                history.push("/login");
            }
        }
      },
      (err) => {
        history.push("/login");
        toast.error("Invalid request");
      },
      (bool) => {
        console.log(bool);
      },
    );
  },
  twoFactorAuth: (formData) => {
    const rememberMe = get().loginData["remember-me"];
    const deviceId = get().loginAction.params.device_identification_code;
    const payload = {
      ...formData,
      device_identification_code: deviceId,
    };
    console.log("remember", rememberMe);
    if (rememberMe) {
      localStorage.setItem("deviceId", deviceId);
    }
    twoFactorAuth(
      payload,
      (data) => {
        set({ loginAction: data.action });
        if (data.api_key !== undefined && data.api_key !== null && data.api_key !== "") {
          localStorage.setItem("token", data.api_key);
          get().setUser(data);
        }
      },
      toast.error,
    );
  },
  updateApplicationForm: (formData, onSuccess) => {
    return updateApplicationForm(
      formData,
      (res) => {
        get().getCoreCV();
        toast.success("Fields updated");
        // history.push(navigation);
        onSuccess && onSuccess();
      },
      (err) => {
        toast.error("Fields could not updated");
        console.log(err);
      },
    );
  },
  getCoreCV: () => {
    getCoreCV(
      (data) => {
        set((state) => ({
          coreCV: { ...state.coreCV, data: data.data, isLoaded: true },
        }));
        get().setAppLanguage(data.data.language.language_iso2);
        // console.log("coreCV:", data.data);
      },
      (err) => console.log(err),
      (bool) => {
        set((state) => ({ coreCV: { ...state.coreCV, isLoading: bool } }));
      },
    );
  },
  coreCVPhoneConfirmation: (formData, onSuccess) => {
    coreCVPhoneConfirmation(
      formData,
      (res) => {
        // get().getCoreCV();
        set({ confirmationAction: res.action });
        if (res.action.params.location === "overview") get().persistSession();
        onSuccess();
      },
      (err) => {
        toast.error(err.response.data.message);
        if (err.response.data.action.type === "redirect") {
          set({ confirmationAction: err.response.data.action });
        }
      },
    );
  },
  getNetworkList: (options, onSuccess) => {
    const payload = {
      type: options?.type || "connections",
      page: options?.page || 1,
      rows: options?.rows || 24,
      keyword: options?.keyword || "",
      search: {
        scope: "self",
        filters: options?.filters || {
          usergroups: [],
          usersource: [],
          work_info: [],
          personality: [],
          badge: [],
        },
        sort: options?.sort || null,
      },
    };

    getNetworkList(
      payload,
      (res) => {
        set((state) => ({
          networkList: {
            ...state.networkList,
            memberList: res.data.rows,
            availableSearchParameters: res.data.available_search_parameters,
            currentSearchParameters: res.data.current_search_parameters,
            isLoaded: true,
            allUserCounts: res.data.all_user_counts,
            totalPages: res.data.total_page,
            totalRows: res.data.total_row_count,
          },
        }));
        // console.log("network list:", res.data);
        return res.data.rows;
      },
      (err) => console.log(err),
      (bool) =>
        set((state) => ({
          networkList: { ...state.networkList, isLoading: bool },
        })),
    );
  },
  clearNetworkList: () => {
    set((state) => ({
      networkList: INITIAL_STATE.networkList,
    }));
  },
  companyInvite: (payload, onSuccess) => {
    companyInvite(
      payload,
      (res) => {
        toast.success("Davetiyeler gonderildi");
        // history.back();
        // onSuccess();
      },
      (err) => {
        console.log(err);
      },
    );
  },
  handleFavMetaCv: (id, isFav) => {
    // console.log(id, isFav);
    const oldNetworkList = get().networkList;
    const newMemberList = oldNetworkList.memberList.map((member) =>
      member.id === id
        ? {
            ...member,
            all_sources: [...member.all_sources, "favourites"],
          }
        : member,
    );
    set((state) => ({
      networkList: { ...state.networkList, memberList: newMemberList },
    }));
    // console.log(oldNetworkList);

    const payload = {
      type: "favourite",
      target: id,
      action: {
        toggle: true,
      },
    };

    metaCvActions(
      payload,
      (res) => {
        toast.success("Added to favourites");
        // console.log("liked succesfuly");
      },
      (err) => {
        toast.error("Cannot added to favourites");
        set((state) => ({ networkList: oldNetworkList }));
        console.log(err);
      },
      (bool) => {
        // console.log(bool);
      },
    );
  },
  removeFavMetaCv: (id, isFav) => {
    // console.log(id, isFav);
    const oldNetworkList = get().networkList;
    const newMemberList = oldNetworkList.memberList.map((member) =>
      member.id === id
        ? {
            ...member,
            all_sources: member.all_sources.filter((item) => item !== "favourites"),
          }
        : member,
    );
    set((state) => ({
      networkList: { ...state.networkList, memberList: newMemberList },
    }));
    const payload = {
      type: "favourite",
      target: id,
      action: {
        toggle: false,
      },
    };
    metaCvActions(
      payload,
      (res) => {
        toast.success("Removed from favourites");
        // console.log("unliked succesfuly");
      },
      (err) => {
        toast.error("Cannot removed from favourites");
        set((state) => ({ networkList: oldNetworkList }));
        console.log(err);
      },
      (bool) => {
        // console.log(bool);
      },
    );
  },
  archiveMetaCv: (id, toggle = true) => {
    const payload = { type: "archive", target: id, action: { toggle: toggle } };
    return metaCvActions(payload, (res) => toast.success(`${res.message}`, (err) => console.log(err)));
  },
  unlockMetaCv: (id) => {
    const payload = { type: "unlock", target: id, action: {} };

    return metaCvActions(
      payload,
      (res) => {
        const oldMemberList = get().networkList.memberList;
        // console.log("oldMemberList", oldMemberList);
        const newMemberList = oldMemberList.map((member) =>
          member.id === id
            ? {
                ...member,
                name: res.data.name,
                is_unlocked: true,
                all_sources: [...res.data.all_sources],
              }
            : member,
        );
        set((state) => ({
          networkList: { ...state.networkList, memberList: newMemberList },
        }));
        toast.success(`successful: ${res}`);
      },
      (err) => toast.error(err),
    );
  },
  getCommunityCandidate: (options) => {
    const payload = {
      who_viewed_search: {
        rows: options.rows,
        page: options.page,
        filters: {
          usergroups: options.usergroups,
        },
      },
    };
    getCommunityCandidate(
      payload,
      (res) =>
        set((state) => ({
          communityCandidate: {
            ...state.communityCandidate,
            data: res,
            isLoaded: true,
          },
        })),
      (err) => console.log(err),
      (bool) =>
        set((state) => ({
          communityCandidate: {
            ...state.communityCandidate,
            isLoading: bool,
          },
        })),
    );
  },
  getContent: (params) => {
    getContent(
      params,
      (res) => {
        // console.log("content", res.data);
        set((state) => ({
          content: { ...state.content, data: res.data, isLoaded: true },
        }));
      },
      (err) => console.log(err),
      (bool) => {
        set((state) => ({ content: { ...state.content, isLoading: bool } }));
      },
    );
  },
  getStrapiContent: (params, extendUrl) => {
    getStrapiContent(
      params,
      extendUrl,
      (res) => {
        // console.log("content", res.data);
        set((state) => ({
          strapiContent: {
            ...state.strapiContent,
            data: res.data,
            isLoaded: true,
          },
        }));
      },
      (err) => console.log(err),
      (bool) => {
        set((state) => ({
          strapiContent: { ...state.strapiContent, isLoading: bool },
        }));
      },
    );
  },
  setAppLanguage: (lang) => {
    localStorage.setItem("lang", lang);
    set({ appLanguage: lang });
    // window.location.reload();
  },
  detectOs: () => {
    const userAgent = window.navigator.userAgent;
    // console.log("os", userAgent);
    if (/Windows/.test(userAgent)) {
      // console.log("os set to Windows");
      set((state) => ({ os: "Windows" }));
    } else if (/Mac/.test(userAgent)) {
      // console.log("os set to Mac OS ");
      set((state) => ({ os: "Mac OS" }));
    } else if (/Linux/.test(userAgent)) {
      // console.log("os set to Linux");
      set((state) => ({ os: "Linux" }));
    } else if (/Android/.test(userAgent)) {
      // console.log("os set to Android");
      set((state) => ({ os: "Android" }));
    } else if (/iOS/.test(userAgent)) {
      // console.log("os set to iOS");
      set((state) => ({ os: "iOS" }));
    } else {
      // console.log("os set to Unknown");
      set((state) => ({ os: "Unknown" }));
    }
  },
  downloadMetaCv: (userId, userName) => {
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
    downloadMetaCv(
      { id: userId },
      (res) => {
        // console.log(res);
        const pdfBlob = new Blob([res], { type: "application/pdf" });

        blobToBase64(pdfBlob).then((res) => {
          const url = res;
          const tempLink = document.createElement("a");
          tempLink.href = url;

          const date = new Date().toLocaleString("tr-TR").split(" ")[0];
          tempLink.setAttribute("download", `${userName}_MetaCV_${date}.pdf`);
          tempLink.setAttribute("target", "_blank");

          document.body.appendChild(tempLink);
          tempLink.click();
          // console.log("clicked");
          document.body.removeChild(tempLink);
          window.URL.revokeObjectURL(url);
          // console.log("download data", url, tempLink);
        });

        // const url = window.URL.createObjectURL(pdfBlob);

        // const tempLink = document.createElement("a");
        // tempLink.href = url;

        // const date = new Date().toLocaleString("tr-TR").split(" ")[0];
        // tempLink.setAttribute("download", `${userName}_MetaCV_${date}.pdf`);
        // tempLink.setAttribute("target", "_blank");

        // document.body.appendChild(tempLink);
        // tempLink.click();
        // // console.log("clicked");
        // document.body.removeChild(tempLink);
        // window.URL.revokeObjectURL(url);
        // console.log("download data", url, tempLink);
      },
      (err) => {
        console.log(err);
        toast.error("Hata");
      },
      (bool) => {
        bool ? toast.success("Dosya Hazirlaniyor") : console.log(bool);
      },
    );
  },

  toggleUserInSelectedUsers: (_user) => {
    let selectedUsers = get().selectedUsers;
    let isExist = selectedUsers.some((user) => user.id === _user.id);
    if (!isExist) {
      set((state) => ({ selectedUsers: [...state.selectedUsers, _user] }));
    } else {
      set((state) => ({
        selectedUsers: [...state.selectedUsers].filter((user) => user.id !== _user.id),
      }));
    }
  },

  toggleSelectAll: (toggle, networkUsers) => {
    const merge = (a, b, predicate = (a, b) => a === b) => {
      const c = [...a]; // copy to avoid side effects
      // add all items from B to copy C if they're not already present
      b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)));
      return c;
    };
    toggle
      ? set((state) => ({
          selectedUsers: merge([...state.selectedUsers], networkUsers, (a, b) => a.id === b.id),
        }))
      : set({ selectedUsers: [] });
  },
  clearSelectedUsers: () => {
    set({ selectedUsers: [] });
  },

  deleteInvite: (id) => {
    const params = {
      invite_id: id,
    };
    return deleteInvite(
      params,
      (res) => {
        toast.success("Davetiye Silindi");
      },
      (err) => {
        toast.error(err.data.message);
      },
    );
  },
  resendInvite: (id) => {
    const params = {
      invite_id: id,
    };
    return resendCompanyInvite(
      params,
      (res) => {
        toast.success("Davetiye Tekrar Gönderildi");
      },
      (err) => {
        toast.error(err.data.message);
      },
    );
  },
  // GROUPS
  getGroupList: () => {
    return getGroupList(
      (res) => {
        set((state) => ({
          groupList: {
            ...state.groupList,
            data: res.data,
            isLoaded: true,
          },
        }));
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        set((state) => ({
          groupList: { ...state.groupList, isLoading: bool },
        }));
      },
    );
  },
  createGroup: (formData, onSuccess) => {
    const payload = {
      action: {
        type: "create",
        params: formData,
      },
    };
    return groupActions(
      payload,
      (res) => {
        onSuccess();
        get().getGroupList();
      },
      (err) => toast.error(err),
      // (bool) => console.log(bool), onLoading
    );
  },
  deleteGroup: (groupId) => {
    const payload = {
      action: {
        type: "delete",
        params: { group_id: groupId },
      },
    };
    return groupActions(
      payload,
      (res) => {
        get().getGroupList();
      },
      (err) => toast.error(err),
      // (bool) => console.log(bool), onLoading
    );
  },
  editGroup: (groupId, formData, onSuccess) => {
    const payload = {
      action: {
        type: "update",
        params: {
          group_id: groupId,
          ...formData,
        },
      },
    };
    return groupActions(
      payload,
      (res) => {
        onSuccess();
        get().getGroupList();
      },
      (err) => toast.error(err),
      // (bool) => console.log(bool), onLoading
    );
  },
  // REPORTS
  saveReport: (formData) => {
    const payload = {
      action: {
        type: "create",
        params: formData,
      },
    };
    return reportActions(
      payload,
      (res) => {
        toast.success(res.message);
        get().getAllReports();
      },
      (err) => {
        toast.error(err.message);
      },
      (bool) => {
        // console.log(bool);
      },
    );
  },
  editReport: (formData) => {
    const payload = {
      action: {
        type: "edit",
        params: formData,
      },
    };
    return reportActions(
      payload,
      (res) => {
        toast.success(res.message);
        get().getAllReports();
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        // console.log(bool);
      },
    );
  },
  deleteReport: (id) => {
    const payload = {
      action: {
        type: "delete",
        params: {
          id: id,
        },
      },
    };
    return reportActions(
      payload,
      (res) => {
        toast.success(res.message);
        get().getAllReports();
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        // console.log(bool);
      },
    );
  },
  getAllReports: () => {
    return getAllReports(
      (res) => {
        set((state) => ({
          savedReports: {
            ...state.savedReports,
            data: res.data,
            isLoaded: true,
          },
        }));
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        set((state) => ({
          savedReports: { ...state.savedReports, isLoading: bool },
        }));
      },
    );
  },
  getSettings: () => {
    return getSettings(
      (res) => {
        //pp update
        set((state) => ({
          settings: { ...state.settings, data: res.data, isLoaded: true },
          user: {
            ...state.user,
            data: {
              ...state.user.data,
              userPicture: res.data.user_profile.profile_picture.value,
            },
          },
        }));
        //lang update
        get().setAppLanguage(res.data.user_account.language_iso2.value);
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        set((state) => ({
          settings: { ...state.settings, isLoading: bool },
        }));
      },
    );
  },
  setSettings: (formData) => {
    return setSettings(
      formData,
      (res) => {
        get().getSettings();
        Object.keys(res.data).map((key) =>
          res.data[key].status > 0 ? toast.success(res.data[key].message) : toast.error(res.data[key].message),
        );
      },
      (err) => {
        Object.keys(err.response.data.data).map((key) =>
          err.response.data.data[key].status > 0
            ? toast.success(err.response.data.data[key].message)
            : toast.error(err.response.data.data[key].message),
        );
        get().getSettings();
      },
      (bool) => {
        // console.log(bool)
      },
    );
  },
  getAid: () => {
    return getAid(
      (res) => {
        set((state) => ({
          aid: { ...state.aid, data: res.data, isLoaded: true },
        }));
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        set((state) => ({ aid: { ...state.aid, isLoading: bool } }));
      },
    );
  },
  uploadInviteList: (file) => {
    return uploadInviteList(
      file,
      (res) => {
        console.log(res);
      },
      (err) => {
        console.log(err);
      },
      (bool) => {
        console.log(bool);
      },
    );
  },
  getJobPositionList: async (options) => {
    const params = {
      page: 1,
      rows: 20,
      ...options,
    };
    await getJobPosition(
      params,
      null,
      (res) =>
        set((state) => ({
          jobPositions: {
            ...state.jobPositions,
            data: res.data,
            isLoaded: true,
          },
        })),
      (err) => console.log(err),
      (bool) =>
        set((state) => ({
          jobPositions: {
            ...state.jobPositions,
            isLoading: bool,
          },
        })),
    );
  },
  addJobPosition: async (formData, onSuccess, options) => {
    await postJobPosition(
      null,
      formData,
      null,
      (res) => {
        toast.success("Yeni Pozisyon Eklendi");
        get().getJobPositionList(options);
        onSuccess && onSuccess();
      },
      (err) => {
        toast.success("Pozisyon Ekleme Başarısız");
        // console.log(err);
      },
      (bool) => {
        // console.log(bool);
      },
    );
  },
  getCnaDashboard: async () => {
    getCnaDashboard(
      (res) =>
        set((state) => ({
          cnaDashboard: {
            ...state.cnaDashboard,
            data: res.data,
            isLoaded: true,
          },
        })),
      (err) => console.log(err),
      (bool) =>
        set((state) => ({
          cnaDashboard: {
            ...state.cnaDashboard,
            isLoading: bool,
          },
        })),
    );
  },
  getCnaConnections: async (options) => {
    const params = {
      page: 1,
      rows: 20,
      ...options,
    };
    await getCnaConnections(
      params,
      null,
      (res) =>
        set((state) => ({
          cnaConnections: {
            ...state.cnaConnections,
            data: res.data,
            isLoaded: true,
          },
        })),
      (err) => console.log(err),
      (bool) =>
        set((state) => ({
          cnaConnections: {
            ...state.cnaConnections,
            isLoading: bool,
          },
        })),
    );
  },
}));
