import React, { useEffect, useState } from "react";
import { ConnectionsWorld } from "../../../components/NewDesign/Illustrations";
import { TagWithIcon } from "../../../components/NewDesign/Tags";
import SearchBar from "../../../components/NewDesign/SearchBar/SearchBar";
import FilterBar from "../../../components/NewDesign/FilterBar";
import SortBar from "../../../components/NewDesign/SortBar";
import { useStore } from "../../../store/useStore";
import Loading from "../../../components/OldDesign/Loading";
import { getNetworkList } from "../../../api/endpoints";
import GroupFilters from "../../../components/NewDesign/GroupFilters";
import { RubbishOutlined, WaitingOutlined } from "../../../components/OldDesign/Icons";
import ConfirmationPopup from "../../../components/NewDesign/Popups/ConfirmationPopup";
import LevelBar from "../../../components/NewDesign/StatusBar/LevelBar";
import IsMobile from "../../../utils/IsMobile";
import { useLocation, useNavigate } from "react-router-dom";
import MobileMetaCv from "../../../components/MobileMetaCv/MobileMetaCv";
import Pagination from "../../../components/OldDesign/Pagination";
import Button from "../../../components/NewDesign/Button/Button";

const INVITEES_GROUPS = ["invited", "playing", "fillingCoreCv", "completed"];
const INVITEES_SOURCES = ["candidate", "employee"];

const Invitees = () => {
  const content = useStore(
    (state) => state.content.data["My Network"].content.translations["Recruiter My Network Tables"],
  );
  const deleteInvite = useStore((state) => state.deleteInvite);
  const resendInvite = useStore((state) => state.resendInvite);

  const [searchKeyword, setSearchKeyword] = useState("");
  const [inviteeList, setInviteeList] = useState({ data: {}, isLoaded: false, isLoading: false });
  const [fetching, setFetching] = useState(false);
  const [selectedUserGroups, setSelectedUserGroups] = useState([]);
  const [selectedSources, setSelectedSources] = useState([]);
  const [allUserCounts, setAllUserCounts] = useState(null);

  const [deleteMsg, setDeleteMsg] = useState("");
  const [deleteInviteeId, setDeleteInviteeId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteCount, setDeleteCount] = useState(0);

  const [resendMsg, setResendMsg] = useState("");
  const [resentInviteeId, setResentInviteeId] = useState(null);
  const [showResendModal, setShowResendModal] = useState(false);

  // PAGINATION
  const [pageSize, setPageSize] = useState(6); // page size
  const [currentPage, setCurrentPage] = useState(1); // current page

  const handleRemoveInvite = (id) => {
    const deleteInviteeName = inviteeList.data.users.rows.find((invite) => invite.invite_id === id).inviteUserName;
    setDeleteMsg(`"${deleteInviteeName}" davetini silmek istediğinize emin misiniz?`);
    setDeleteInviteeId(id);
    setShowDeleteModal(true);
  };

  const handleResendInvite = (id) => {
    const resendInviteeName = inviteeList.data.users.rows.find((invite) => invite.invite_id === id).inviteUserName;
    setResendMsg(`"${resendInviteeName}" davetini tekrar göndermek istediğinize emin misiniz?`);
    setResentInviteeId(id);
    setShowResendModal(true);
  };

  useEffect(() => {
    const payload = {
      type: "invitees",
      page: currentPage,
      rows: pageSize,
      keyword: searchKeyword,
      search: {
        scope: "self",
        filters: {
          usergroups: selectedUserGroups,
          usersource: selectedSources,
        },
        sort: {
          sortby: "name",
          ascending: true,
        },
      },
    };
    getNetworkList(
      payload,
      (res) => {
        setInviteeList((prev) => {
          return { ...prev, data: res.data, isLoaded: true };
        });
        // setAllUserCounts(res.data.users.all_user_counts);
      },
      (err) => console.log(err),
      (bool) =>
        setInviteeList((prev) => {
          return { ...prev, isLoading: bool };
        }),
    );
  }, [selectedUserGroups, searchKeyword, deleteCount, selectedSources, currentPage, pageSize]);

  if (!inviteeList.isLoaded) {
    return null;
  }
  return (
    <>
      {/* <p className="pb-2 text-[14px]">{content["invitees aciklama"]}</p> */}
      <div className="flex items-center justify-between py-4 max-lg:py-2">
        <SearchBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
        {/* <FilterBar /> */}
        {/* <SortBar /> */}

        {/* Filters */}
      </div>

      <GroupFilters
        fetching={inviteeList.isLoading}
        selectedUserGroups={selectedUserGroups}
        setSelectedUserGroups={setSelectedUserGroups}
        userGroupFilters={INVITEES_GROUPS}
        sourceFilters={INVITEES_SOURCES}
        allUserCounts={inviteeList.data.users.all_user_counts}
        setSelectedSources={setSelectedSources}
        selectedSources={selectedSources}
      />

      <div className="flex flex-col gap-4">
        {/* columns */}
        <div className="flex items-center justify-between rounded-xl bg-neutral-200 px-6 py-3 max-lg:hidden">
          <div className="basis-[15%]">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Ad Soyad</p>
          </div>
          <div className="basis-[20%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">E-posta</p>
          </div>
          <div className="basis-[15%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Meta CV Level</p>
          </div>
          <div className="basis-[15%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Şirket İsmi</p>
          </div>
          <div className="basis-[10%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Tarih</p>
          </div>
          <div className="basis-[15%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Durum</p>
          </div>
          <div className="basis-[10%] text-center">
            <p className="text-[13px] font-medium leading-tight text-primary-900">Aksiyonlar</p>
          </div>
        </div>
        {/* list */}
        {inviteeList.isLoading ? (
          <Loading />
        ) : !inviteeList.data.users.rows.length ? (
          <div className="py-12">
            <ConnectionsWorld className="mx-auto w-1/2 pb-9" />
            <p className="text-center text-[24px] font-semibold leading-tight text-primary-900">
              {content["bos tablo aciklama"]}
            </p>
          </div>
        ) : (
          <>
            {/* List */}
            {inviteeList.data.users.rows.map((item, index) => (
              <UserRow
                handleRemoveInvite={handleRemoveInvite}
                handleResendInvite={handleResendInvite}
                key={index}
                user={item}
              />
            ))}
            <Pagination
              dataLength={inviteeList.data.users.rows.length}
              setPageSize={setPageSize}
              currentPage={currentPage}
              totalCount={inviteeList.data.users.total_row_count}
              pageSize={pageSize}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </>
        )}
      </div>
      <ConfirmationPopup
        onSuccess={() => setDeleteCount((prev) => prev + 1)}
        message={deleteMsg}
        showModal={showDeleteModal}
        setShowModal={setShowDeleteModal}
        id={deleteInviteeId}
        confirmModal={deleteInvite}
        confirmationTopic={"Delete"}
      />
      <ConfirmationPopup
        onSuccess={() => setDeleteCount((prev) => prev + 1)}
        message={resendMsg}
        showModal={showResendModal}
        setShowModal={setShowResendModal}
        id={resentInviteeId}
        confirmModal={resendInvite}
        confirmationTopic={"Resend"}
      />
    </>
  );
};

const UserRow = ({ user, handleRemoveInvite, handleResendInvite }) => {
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const handleOverlayOpen = (id) => {
    setSelectedItemId(id);
    setShowOverlay(true);
  };
  const handleModalOpen = (e) => {
    IsMobile()
      ? handleOverlayOpen(user.userId)
      : navigate(`/view/${user.userId}`, {
          state: { previousLocation: location },
        });
  };
  return (
    <>
      <div className="relative flex items-center justify-between rounded-xl border border-secondary_recruiter-300 px-6 py-4 max-lg:flex-row max-lg:flex-wrap max-lg:items-start max-lg:gap-1 max-lg:p-3">
        <div className="flex flex-col gap-1 overflow-hidden px-0.5 max-lg:order-1 max-lg:basis-[80%] lg:basis-[15%]">
          <p className="text-[16px] font-medium leading-tight text-primary-900 max-lg:text-[15px]">
            {user.inviteUserName}
          </p>
          <p
            className={`${
              !user.is_employee ? "text-secondary_applicant-600" : "text-secondary_recruiter-700"
            } text-[14px] font-medium leading-tight max-lg:text-[13px] max-lg:opacity-50`}
          >
            {user.is_employee ? "Employee" : "Candidate"}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 text-center max-lg:order-3 lg:basis-[20%] lg:text-left">
          <p className="text-sm font-medium leading-tight text-primary-700 max-lg:text-[13px] max-lg:opacity-50">
            {user.inviteEmail || "N/A"}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 max-lg:order-4 max-lg:basis-full lg:basis-[15%]">
          <div className="mb-1 h-[20px] w-full shrink-0 overflow-hidden rounded-full bg-primary-100">
            <div
              style={{
                width: user.metacv_level > 0 ? (100 * user.metacv_level) / 3 + "%" : "100%",
              }}
              className={`${
                user.metacv_level > 0
                  ? "bg-[linear-gradient(78deg,#0D568A_0%,#2878BE_37.5%,#0DC9B1_100%)] text-neutral-100"
                  : "bg-transparent text-neutral-900"
              } flex h-full items-center justify-center gap-1 rounded-full px-2 py-0.5 text-center leading-none`}
            >
              <p className={`whitespace-nowrap text-[13px]`}>Level {user.metacv_level}</p>
            </div>
          </div>
          {user.metacv_level > 0 && (
            <p className="text-[14px] font-medium leading-tight text-secondary_recruiter-600">{user.last_played}</p>
          )}
        </div>
        <div className="overflow-hidden px-0.5 text-center max-lg:order-5 lg:basis-[15%]">
          <p className="text-[16px] font-medium leading-tight text-primary-700 max-lg:text-[13px] max-lg:opacity-50">
            {user.company_name || "N/A"}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 text-center max-lg:order-6 lg:basis-[10%]">
          <p className="text-[16px] font-medium leading-tight text-primary-700 max-lg:text-[13px] max-lg:opacity-50">
            {user.invite_date || "N/A"}
          </p>
        </div>
        <div className="overflow-hidden px-0.5 max-lg:order-7 max-lg:basis-full lg:basis-[15%] lg:px-3">
          {user.invitee_status_id === "completed" ? (
            <button onClick={(e) => handleModalOpen(e)}>
              <TagWithIcon text={`${user.inviteeStatus}`} className={"bg-success-100 text-success-400"} />
            </button>
          ) : (
            <TagWithIcon
              text={`${user.inviteeStatus}`}
              className={`${
                user.invitee_status_id === "invited"
                  ? "bg-warning-100 text-warning-400"
                  : user.invitee_status_id === "playing"
                  ? "bg-primary-75 text-primary-600"
                  : user.invitee_status_id === "fillingCoreCv"
                  ? "bg-secondary_recruiter-100 text-secondary_recruiter-600"
                  : user.invitee_status_id === "completed"
                  ? "bg-success-100 text-success-400"
                  : ""
              }`}
            />
          )}
        </div>
        <div className="flex basis-[5%] items-center justify-center gap-1 px-0.5 max-lg:order-2 max-lg:basis-[10%] lg:px-3">
          <Button
            type="button"
            disabled={user.invitee_status_id === "completed"}
            className="rounded-lg border-none bg-success-100 p-2.5"
            onClick={() => handleResendInvite(user.invite_id)}
            icon={<WaitingOutlined className="h-[22px] w-[22px] text-success-400 max-lg:h-4 max-lg:w-4" />}
          ></Button>
          <Button
            type="button"
            disabled={user.invitee_status_id === "completed"}
            className="rounded-lg border-none bg-card_candidate-100 p-2.5"
            onClick={() => handleRemoveInvite(user.invite_id)}
            icon={<RubbishOutlined className="h-[22px] w-[22px] text-card_candidate-400 max-lg:h-4 max-lg:w-4" />}
          ></Button>
        </div>
      </div>
      {showOverlay && <MobileMetaCv id={selectedItemId} onClose={() => setShowOverlay(false)} />}
    </>
  );
};

export default Invitees;
