import React from "react";
import { Controller, useForm } from "react-hook-form";
import AsyncSelect from "react-select/async";
import { Form } from "../../Forms/Form";
import { Field } from "../../Forms/Field";
import { useStore } from "../../../../store/useStore";
import { CloseOutlined, LeftCircularOutlined } from "../../../OldDesign/Icons";
import { REACT_BASE_URL } from "../../../../api/constants";
import { ASelect, CrASelect } from "../../Forms/Input";

const SkillsCard = (props) => {
  const coreCV = useStore((state) => state.coreCV.data);
  const updateApplicationForm = useStore((state) => state.updateApplicationForm);
  const content = useStore((state) => state.content.data["Candidate Community"].content.translations["Core CV"]);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      skills_array: coreCV.skills_array.length > 0 ? coreCV.skills_array : [],
      user_job: coreCV.user_job.job_title_id
        ? {
            job_id: coreCV.user_job.job_title_id,
            job_name: coreCV.user_job.job_name,
            job_experience_id: 1,
            job_experience_title: 1,
          }
        : "",
    },
    mode: "onSubmit",
  });

  const loadJobTitleOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/job?rows=100&page=1&keyword=${value}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };
  const loadSkillsOptions = async (value) => {
    const apiKey = localStorage.getItem("token");
    const res = await fetch(`${REACT_BASE_URL}/content/skill?rows=100&page=1&keyword=${encodeURIComponent(value)}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${apiKey}`,
      },
    });
    const data = await res.json();
    const options = data.data.rows;
    if (options.length === 0) {
      return [];
    }
    return options;
  };

  const sendFormData = (formData) => {
    const payload = {
      skills_array: [
        ...formData.skills_array.map((skill) => {
          return { ...skill, skill_proficiency: 0 };
        }),
      ],
      user_job: {
        ...formData.user_job,
        job_title_id: formData.user_job.job_id,
        job_experience_id: 1,
        job_experience_title: 1,
      },
    };
    // console.log(payload);
    updateApplicationForm(payload, props.handleForwardForm);
  };

  return (
    <>
      <Form className="flex h-full flex-col justify-between" onSubmit={handleSubmit(sendFormData)}>
        <fieldset className="flex flex-col gap-4">
          <h2 className="border-l-[2px] border-l-primary-700 pl-1 text-[16px] font-semibold leading-tight text-primary-700">
            {content["mesleki detaylar baslik"]}
          </h2>
          <Field label={content["mesleginiz baslik"]} error={errors?.user_job}>
            <Controller
              control={control}
              name="user_job"
              rules={{ required: "Required" }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <CrASelect
                  defaultValue={value}
                  selected={value}
                  onBlur={onBlur}
                  defaultOptions
                  loadOptions={loadJobTitleOptions}
                  onChange={onChange}
                  placeholder="Seçiniz"
                  getOptionLabel={(opt) => opt.job_name}
                  getOptionValue={(opt) => opt.job_id}
                  className={"max-h-[210px]"}
                  getNewOptionData={(inputValue, optionLabel) => ({
                    job_name: inputValue,
                    __isNew__: true,
                  })}
                />
              )}
            />
          </Field>
          <Field
            label={
              <div className="flex items-center justify-between">
                {content["yetenekler baslik"]}
                <span className="text-xs text-neutral-700">Max 20</span>
              </div>
            }
            error={errors?.skills_array}
          >
            <Controller
              control={control}
              rules={{ required: "Required" }}
              name="skills_array"
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <ASelect
                  isMulti
                  defaultValue={value}
                  selected={value}
                  onBlur={onBlur}
                  defaultOptions
                  loadOptions={loadSkillsOptions}
                  isOptionDisabled={(opt) => value.length >= 20}
                  onChange={onChange}
                  placeholder="Seçiniz"
                  getOptionLabel={(opt) => opt.skill_name}
                  getOptionValue={(opt) => opt.skill_name}
                  className={"max-h-[210px]"}
                />
              )}
            />
          </Field>
        </fieldset>
        <div className="flex justify-center gap-20">
          <button disabled={false} type="button" id="backwardArrow" onClick={() => props.handleBackwardForm()}>
            <LeftCircularOutlined className="h-10 w-10 text-primary-600 " />
          </button>
          <button disabled={false} type="submit" id="forwardArrow">
            <LeftCircularOutlined className="h-10 w-10 rotate-180 text-primary-600" />
          </button>
        </div>
      </Form>
    </>
  );
};
export default SkillsCard;
