import React, { useCallback, useEffect, useRef, useState } from "react";
import { BagIconFilled, CloseCircleIcon, RightArrowOutlined, SendFilled } from "../OldDesign/Icons";
import { TagWithIcon } from "../NewDesign/Tags";
import ActionBar from "../NewDesign/PrintableCv/ActionBar";
import CvFooter from "../NewDesign/PrintableCv/CvFooter";
import Avatar from "../NewDesign/Avatars/Avatar";
import CvAID from "../NewDesign/PrintableCv/CvAID";
import CvHeader from "../NewDesign/PrintableCv/CvHeader";
import CvCore from "../NewDesign/PrintableCv/CvCore";
import ProfessionalOrientationSection from "../NewDesign/PrintableCv/ProfessionalOrientationSection";
import CvInterviewQuestions from "../NewDesign/PrintableCv/CvInterviewQuestions";
import CvComments from "../NewDesign/PrintableCv/CvComments";

const MobileMetaCv = ({ setMetaCv, metaCv, onClose }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [showTopScroller, setShowTopScroller] = useState(0);
  const metaCvRef = useRef(null);
  const tabs = [
    { title: "Özet", component: <CvHeader user={metaCv} /> },
    { title: "Core CV", component: <CvCore user={metaCv} /> },
    { title: "Mesleki Yönelim", component: <ProfessionalOrientationSection user={metaCv} /> },
    { title: "Humanas Kimliği", component: <CvAID user={metaCv} /> },
    { title: "Mülakat Soruları", component: <CvInterviewQuestions user={metaCv} /> },
    {
      title: "Yorumlar",
      component: (
        <>
          <CvComments user={metaCv} />
          <CvFooter user={metaCv} />
        </>
      ),
    },
  ];
  const controlHeader = useCallback(() => {
    if (metaCvRef.current.scrollTop > 340) {
      // if scroll down hide the navbar
      // console.log("gorun");
      setShowTopScroller(true);
    } else {
      // if scroll up show the navbar
      // console.log("gizle");
      setShowTopScroller(false);
    }
  }, []);
  const handleScrollToCommentSection = () => {
    setActiveTabIndex(tabs.length - 1);
  };
  const handleCloseOverlay = (e) => {
    e.stopPropagation();
    onClose();
  };

  const handleTabChange = (tabIndex) => {
    setActiveTabIndex(tabIndex);
  };

  const handleTopScroll = (e) => {
    metaCvRef.current.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    const scrollableCointainer = metaCvRef.current;
    // We only attach the scroll listener once the data is loaded
    if (scrollableCointainer) {
      // Add the scroll event listener
      scrollableCointainer.addEventListener("scroll", controlHeader);
      // Remove the scroll event listener when component unmounts
      return () => {
        scrollableCointainer.removeEventListener("scroll", controlHeader);
      };
    }
  }, [controlHeader]);

  return (
    <div ref={metaCvRef} className="fixed left-0 top-0 z-[100] h-screen w-screen overflow-y-scroll bg-neutral-100">
      <button className="fixed right-2 top-2 z-50" onClick={handleCloseOverlay}>
        <CloseCircleIcon className="h-6 w-6" />
      </button>

      <div className="w-full rounded-b-3xl bg-primary-50 ">
        <div className="px-4 pb-2 pt-6">
          <div className="flex flex-row justify-start gap-2 pb-2">
            <Avatar user={metaCv} />
            <div className="flex flex-col items-start justify-between">
              <h2 className="text-[14px] font-semibold leading-tight text-primary-900">{metaCv.name}</h2>
              <p className="text-[13px] leading-tight text-neutral-900">{metaCv.job.job_name}</p>
              <div className="flex justify-center gap-2">
                <TagWithIcon
                  icon={<SendFilled className="h-[14px] w-[14px]" />}
                  className="bg-secondary_recruiter-100 px-2 py-1 text-secondary_recruiter-700"
                  text={`${true ? "Employee" : "Candidate"}`}
                />
                <TagWithIcon
                  icon={<BagIconFilled className="h-[14px] w-[14px]" />}
                  className="bg-success-100 px-2 py-1 text-success-400"
                  text={`${metaCv.work_state === "unemployed" ? "Not working" : "Working"}`}
                />
              </div>
            </div>
          </div>
          <ActionBar handleScrollToCommentSection={handleScrollToCommentSection} user={metaCv} setMetaCv={setMetaCv} />
        </div>
        <div className="scrollbar-hide flex items-center gap-1.5 overflow-x-auto p-1">
          {tabs.slice(0, tabs.length - 1).map((tab, index) => (
            <button
              onClick={() => handleTabChange(index)}
              className={`${
                activeTabIndex === index
                  ? "border-b-[1.5px] border-primary-700 font-semibold text-primary-700"
                  : "border-b-[0px] border-neutral-100 font-medium text-neutral-400"
              } mx-1 my-2 whitespace-nowrap text-sm  leading-tight transition-all`}
            >
              {tab.title}
            </button>
          ))}
        </div>
      </div>
      <div className="p-2">{tabs[activeTabIndex].component}</div>

      <button
        type="button"
        onClick={() => handleTopScroll()}
        className={`${
          showTopScroller ? "bottom-6 right-6" : "-bottom-20 right-6"
        } fixed z-50 rounded-full bg-primary-600 p-3 transition-all lg:hidden`}
      >
        <RightArrowOutlined className={"h-8 w-8 -rotate-90 text-neutral-100"} />
      </button>
    </div>
  );
};

export default MobileMetaCv;
